// many icons come from Font Awesome without alteration, license: https://fontawesome.com/license
fetch('/icons.svg').then((r) => {
	r.text().then((rr) => {
		var div = document.createElement('div');
		div.innerHTML = rr;
		div.style.display = 'none';
		document.body.append(div);
	});
});

export function Icon(p: { name: string; style?: object }) {
	return (
		<span class="icon" style={p.style as any}>
			<svg>
				<use href={'#' + p.name}></use>
			</svg>
		</span>
	);
}

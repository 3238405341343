import { makeAutoObservable } from 'mobx';
import { For, JSXElement } from 'solid-js';

interface IWindow {
	comp: (p: any) => JSXElement;
	resolve: (data: any) => void;
	props: any;
	el?: HTMLElement;
}

class WindowMgr {
	list: IWindow[] = [];

	constructor() {
		makeAutoObservable(this);
	}

	open<T>(comp: (p: T) => JSXElement, props: T): Promise<any> {
		return new Promise((resolve, reject) => {
			this.list.push({
				comp,
				props,
				resolve
			});
		});
	}

	close(el: HTMLElement, data?: any) {
		for (let i = this.list.length - 1; i >= 0; i--) {
			let w = this.list[i];
			if (w.el === el) {
				this.list.splice(i, 1);
				w.resolve(data);
				break;
			}
		}
	}
}

export function Windows() {
	return (
		<For each={dlg.list}>
			{(w) => {
				w.el = w.comp(w.props) as HTMLElement;
				return [w.el];
			}}
		</For>
	);
}

export const dlg = new WindowMgr();

import { css } from 'goober';
import { dlg } from '../dlg';
import { Icon } from '../components/icon';

const c_style = css`
	width: 600px;
	padding: 20px;
	background: #222;
	border-width: 0;
	color: white;
`;

function get_class_list(names: string[]) {
	let r = {};
	names.forEach((n) => (r[n] = true));
	return r;
}

function Guidelines() {
	let el: HTMLDivElement;
	return (
		<div class="dlgbg" ref={el}>
			<div classList={get_class_list(['dlg', c_style])}>
				<div style={{ 'text-align': 'center' }}>
					<h1>Guidelines</h1>
					<Icon name={'safety'} style={{ 'font-size': '30px' }} />
					<p>Here are some best practices to follow for safety</p>
				</div>
				<h4 style={{ 'margin-top': '10px', 'margin-bottom': 0 }}>For Everyone</h4>
				<ul>
					<li>If needed, prefer to meet in public locations</li>
					<li>Do not give out your home address in a post</li>
					<li>Beware of scams when working with new providers</li>
				</ul>
				<h4 style={{ 'margin-top': '10px', 'margin-bottom': 0 }}>For Providers</h4>
				<ul>
					<li>Work in teams of two when meeting people</li>
					<li>Generally avoid giving cash, prefer to make direct payments to a vendor or use gift cards</li>
				</ul>
				<div class="flex jcenter">
					<button onClick={(e) => dlg.close(el)}>Close</button>
				</div>
			</div>
		</div>
	);
}

export function openGuidelines() {
	return dlg.open(Guidelines, {});
}

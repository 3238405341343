import { css } from 'goober';
import { dlg } from '../dlg';
import { api } from '../services/api';
import { state } from '../services/state';
import { removeFromArray } from '../utils/utils';

const c_style = css`
	width: 600px;
	padding: 20px;
	align-items: center;
	background: #222;
	border-width: 0;
	color: white;
`;

function BadgeDlg(p: { badges: IBadge[]; org_id: number }) {
	let el: HTMLDivElement;

	function current_org_badge(): IBadge {
		let found = p.badges.find((b) => b.by_org_id === state.user_org?.id);
		return found;
	}

	async function toggle_badge() {
		let found = current_org_badge();
		if (found) {
			await api.delete_badge(found);
			removeFromArray(found, p.badges);
		} else {
			let b: IBadge = {
				org_id: p.org_id,
				by_org_id: state.user_org.id,
				by_user_id: state.user.id,
				org: { name: state.user_org.name }
			};
			await api.create_badge(b);
			p.badges.push(b);
		}
	}

	return (
		<div class="dlgbg" ref={el}>
			<div class="dlg">
				<div class="dlg-header">Badges by Organization</div>
				<div class="dlg-content gap10">
					{p.badges.map((b) => (
						<div>
							<span>★ {b.org.name}</span>
							{b.by_org_id === state.user_org?.id && (
								<a class="danger" style={{ 'margin-left': '20px' }} onClick={toggle_badge}>
									Remove
								</a>
							)}
						</div>
					))}
					{!p.badges.length && <div>No Badges</div>}
				</div>
				<div class="dlg-footer">
					{state.is_org_user && !current_org_badge() && (
						<button class="primary" onClick={(e) => toggle_badge()}>
							Add Badge
						</button>
					)}
					<button onClick={(e) => dlg.close(el)}>Close</button>
				</div>
			</div>
		</div>
	);
}

const c_badges = css`
	padding: 2px;
	background: none;
	border: none;
	text-decroration: none;
	&:hover {
		background: lime;
	}
`;

export function Badges(p: { org_id: number; badges: IBadge[] }) {
	return (
		<button
			class={c_badges}
			onClick={(e) => {
				e.stopPropagation();
				dlg.open(BadgeDlg, { org_id: p.org_id, badges: p.badges });
			}}
		>
			👍&nbsp;{p.badges.length}
		</button>
	);
}

import { makeAutoObservable } from 'mobx';
import { BackHomeBar } from '../components/backHome';
import { state } from '../services/state';
import { api } from '../services/api';

export function OrgPage() {
	state.show_user_bar = false;

	const l = makeAutoObservable({ org: null as IOrg });

	async function load() {
		let hash = document.location.pathname;
		let orgPath = hash.substring(hash.lastIndexOf('/') + 1);
		if (orgPath) {
			l.org = await api.get_org({ path: orgPath });
		}
	}

	load();

	return (
		<div class="grow">
			<BackHomeBar />
			{l.org && (
				<div class="inner" style={{ 'margin-top': '20px' }}>
					<h3>{l.org.name}</h3>
					{l.org.description && <p style={{ 'margin-top': 0 }}>{l.org.description}</p>}
					{/* 
					{state.org_posts.map((p) => (
						<Posting p={p} />
					))}
					{!state.org_posts.length && <div>No Postings Found</div>} */}
				</div>
			)}
		</div>
	);
}

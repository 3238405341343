import { closeLoader, openLoader } from '../components/loading';
import { openToast } from '../dialogs/toast';
import { ApiError } from '../utils/apiError';
import { config } from './config';

export async function make_req<t>(type: string, params?: any, id_obj?: any): Promise<t> {
	let req: IRequest = {
		type,
		params,
		session_id: config.session_id
	};

	let is_error = false;
	const p = fetch(config.base_url + '?' + type, {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(req)
	}).then((r) => {
		if (r.status !== 200) is_error = true;
		return r.json();
	});

	const r: IResponse = await openLoader(p);

	if (is_error) {
		openToast(r.message || 'unknown api error', true);
		throw new ApiError(r.message);
	}

	if (r.message) {
		openToast(r.message);
	}

	if (r?.new_id) {
		if (id_obj) id_obj.id = r.new_id;
		else params.id = r.new_id;
	}
	return r as t;
}

import { makeAutoObservable } from 'mobx';

const l = makeAutoObservable({ show: false, count: 0 });

export function Loading() {
	return (
		<div id="loader">
			{l.show && (
				<div
					style={{
						width: '100%',
						height: '100%',
						top: 0,
						left: 0,
						display: 'flex',
						position: 'fixed',
						'align-items': 'center',
						'justify-content': 'center',
						'z-index': 1000
					}}
				>
					<div class="lds-ellipsis">
						<div></div>
						<div></div>
						<div></div>
						<div></div>
					</div>
				</div>
			)}
		</div>
	);
}

let start = new Date();

export function openLoader<T>(p: Promise<T>): Promise<T> {
	if (l.count == 0) start = new Date();
	l.count++;
	l.show = true;
	p.finally(closeLoader);
	return p;
}

const MIN_SHOW = 500;

export function closeLoader() {
	l.count--;
	let end = new Date();
	let diff = end.getTime() - start.getTime();
	let remaining = MIN_SHOW - diff;
	if (remaining > 0) {
		setTimeout(() => {
			l.show = l.count > 0;
		}, remaining);
	} else {
		l.show = l.count > 0;
	}
}

//@ts-ignore
window.loader = l;

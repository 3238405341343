import { BackHomeBar } from '../components/backHome';
import { state } from '../services/state';

export function PrivacyPolicy() {
	state.show_user_bar = false;
	return (
		<div>
			<BackHomeBar />
			<div class="page legal inner" style={{ padding: '20px' }}>
				<h3>PRIVACY POLICY</h3>
				<div class="innerText">
					This privacy policy has been compiled to better serve those who are concerned with how their
					'Personally Identifiable Information' (PII) is being used online. PII, as described in US privacy
					law and information security, is information that can be used on its own or with other information
					to identify, contact, or locate a single person, or to identify an individual in context. Please
					read our privacy policy carefully to get a clear understanding of how we collect, use, protect or
					otherwise handle your Personally Identifiable Information in accordance with our website.
				</div>
				<p></p>
				<div class="grayText">
					<strong>
						What personal information do we collect from the people that visit our blog, website or app?
					</strong>
				</div>
				<p></p>
				<div class="innerText">
					When ordering or registering on our site, as appropriate, you may be asked to enter your name, email
					address, mailing address, phone number or other details to help you with your experience.
				</div>
				<p></p>
				<div class="grayText">
					<strong>When do we collect information?</strong>
				</div>
				<p></p>
				<div class="innerText">
					We collect information from you when you register on our site, place an order, subscribe to a
					newsletter, fill out a form or enter information on our site.
				</div>
				<p></p>
				<div class="grayText">
					<strong>How do we use your information?</strong>
				</div>
				<p></p>
				<div class="innerText">
					We may use the information we collect from you when you register, make a purchase, sign up for our
					newsletter, respond to a survey or marketing communication, surf the website, or use certain other
					site features in the following ways:
					<p></p>
				</div>
				<div class="innerText">
					<strong>•</strong>
					To allow us to better service you in responding to your customer service requests.
				</div>
				<div class="innerText">
					<strong>•</strong>
					To quickly process your transactions.
				</div>
				<div class="innerText">
					<strong>•</strong>
					To send periodic emails regarding your order or other products and services.
				</div>
				<div class="innerText">
					<strong>•</strong>
					To follow up with them after correspondence (live chat, email or phone inquiries)
				</div>
				<p></p>
				<div class="grayText">
					<strong>How do we protect your information?</strong>
				</div>
				<p></p>
				<div class="innerText">We do not use vulnerability scanning and/or scanning to PCI standards.</div>
				<div class="innerText">
					We only provide articles and information. We never ask for credit card numbers. however, this
					information may be requested through a third-party verified payment processor.
				</div>
				<div class="innerText">
					We use regular Malware Scanning.
					<p></p>
				</div>
				<div class="innerText">
					Your personal information is contained behind secured networks and is only accessible by a limited
					number of persons who have special access rights to such systems, and are required to keep the
					information confidential. In addition, all sensitive/credit information you supply is encrypted via
					Secure Socket Layer (SSL) technology.
				</div>
				<p></p>
				<div class="innerText">
					We implement a variety of security measures when a user places an order enters, submits, or accesses
					their information to maintain the safety of your personal information.
				</div>
				<p></p>
				<div class="innerText">
					All transactions are processed through a gateway provider and are not stored or processed on our
					servers.
				</div>
				<p></p>
				<div class="grayText">
					<strong>Do we use 'cookies'?</strong>
				</div>
				<p></p>
				<div class="innerText">
					We do not use cookies for tracking purposes, but we do use them to validate that you have logged in
					to your account.
				</div>
				<div class="innerText">
					You can choose to have your computer warn you each time a cookie is being sent, or you can choose to
					turn off all cookies. You do this through your browser settings. Since browser is a little
					different, look at your browser's Help Menu to learn the correct way to modify your cookies.
				</div>
				<p></p>
				<div class="innerText">
					If you turn cookies off, some features will be disabled, that make your site experience more
					inefficient and may not function properly.
				</div>
				<p></p>
				<div class="innerText">
					furthermore, you will not be able to place orders without being logged in which requires a cookie.
				</div>
				<p></p>
				<div class="grayText">
					<strong>Third-party disclosure</strong>
				</div>
				<p></p>
				<div class="innerText">
					We do not sell, trade, or otherwise transfer to outside parties your Personally Identifiable
					Information.
				</div>
				<p></p>
				<div class="grayText">
					<strong>Third-party links</strong>
				</div>
				<p></p>
				<div class="innerText">
					We include or offer third-party products or services on our website. These third-party sites have
					separate and independent privacy policies. We therefore have no responsibility or liability for the
					content and activities of these linked sites. Nonetheless, we seek to protect the integrity of our
					site and welcome any feedback about these sites.
				</div>
				<p></p>
				<div class="blueText">
					<strong>Google</strong>
				</div>
				<p></p>
				<div class="innerText">
					Google's advertising requirements can be summed up by Google's Advertising Principles. They are put
					in place to provide a positive experience for users.
					https://support.google.com/adwordspolicy/answer/1316548?hl=en
					<p></p>
				</div>
				<div class="innerText">We may use Google AdSense Advertising on our website.</div>
				<div class="innerText">
					Google, as a third-party vendor, uses cookies to serve ads on our site. Google's use of the DART
					cookie enables it to serve ads to our users based on previous visits to our site and other sites on
					the Internet. Users may opt-out of the use of the DART cookie by visiting the Google Ad and Content
					Network privacy policy.
				</div>
				<div class="innerText">
					<strong>We have implemented the following:</strong>
				</div>
				<div class="innerText">
					<strong>•</strong>
					Google Display Network Impression Reporting
				</div>
				<p></p>
				<div class="innerText">
					We, along with third-party vendors such as Google use first-party cookies (such as the Google
					Analytics cookies) and third-party cookies (such as the DoubleClick cookie) or other third-party
					identifiers together
					<div class="innerText">
						We use them to compile data regarding user interactions to improve user experiences
					</div>
					<div class="innerText">
						<strong>
							Opting out:
							<br />
							Users can set preferences for how Google advertises to you using the Google Ad Settings
							page. Alternatively, you can opt out by visiting the Network Advertising Initiative Opt Out
							page or by using the Google Analytics Opt Out Browser add on.
						</strong>
					</div>
					<p>
						<strong>
							<span id="calOppa"></span>
							<br />
						</strong>
					</p>
					<div class="blueText">
						<strong>California Online Privacy Protection Act</strong>
					</div>
					<p>
						<strong></strong>
					</p>
					<div class="innerText">
						CalOPPA is the first state law in the nation to require commercial websites and online services
						to post a privacy policy. The law's reach stretches well beyond California to require any person
						or company in the United States (and conceivably the world) that operates websites collecting
						Personally Identifiable Information from California consumers to post a conspicuous privacy
						policy on its website stating exactly the information being collected and those individuals or
						companies with whom it is being shared. - See more at:
						http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf
					</div>
					<div class="innerText">
						<strong>According to CalOPPA, we agree to the following:</strong>
					</div>
					<div class="innerText">Users can visit our site anonymously.</div>
					<div class="innerText">
						Once this privacy policy is created, we will add a link to it on our home page or as a minimum,
						on the first significant page after entering our website.
					</div>
					<div class="innerText">
						Our Privacy Policy link includes the word 'Privacy' and can easily be found on the page
						specified above.
					</div>
					<div class="innerText">You will be notified of any Privacy Policy changes:</div>
					<div class="innerText">
						<strong>•</strong>
						On our Privacy Policy Page
					</div>
					<div class="innerText">Can change your personal information:</div>
					<div class="innerText">
						<strong>•</strong>
						By emailing us
					</div>
					<div class="innerText">
						<strong>•</strong>
						By updating your online profile with us
					</div>
					<div class="innerText">
						<strong>How does our site handle Do Not Track signals?</strong>
					</div>
					<div class="innerText">
						We honor Do Not Track signals and Do Not Track, plant cookies, or use advertising when a Do Not
						Track (DNT) browser mechanism is in place.
					</div>
					<div class="innerText">
						<strong>Does our site allow third-party behavioral tracking?</strong>
					</div>
					<div class="innerText">
						It's also important to note that we do not allow third-party behavioral tracking
					</div>
					<p>
						<strong>
							<span id="coppAct"></span>
							<br />
						</strong>
					</p>
					<div class="blueText">
						<strong>COPPA (Children Online Privacy Protection Act)</strong>
					</div>
					<p>
						<strong></strong>
					</p>
					<div class="innerText">
						When it comes to the collection of personal information from children under the age of 13 years
						old, the Children's Online Privacy Protection Act (COPPA) puts parents in control. The Federal
						Trade Commission, United States' consumer protection agency, enforces the COPPA Rule, which
						spells out what operators of websites and online services must do to protect children's privacy
						and safety online.
						<p></p>
					</div>
					<div class="innerText">
						We do not specifically market to children under the age of 13 years old.
					</div>
					<p>
						<strong>
							<span id="ftcFip"></span>
							<br />
						</strong>
					</p>
					<div class="blueText">
						<strong>Fair Information Practices</strong>
					</div>
					<p>
						<strong></strong>
					</p>
					<div class="innerText">
						The Fair Information Practices Principles form the backbone of privacy law in the United States
						and the concepts they include have played a significant role in the development of data
						protection laws around the globe. Understanding the Fair Information Practice Principles and how
						they should be implemented is critical to comply with the various privacy laws that protect
						personal information.
						<p></p>
					</div>
					<div class="innerText">
						<strong>
							In order to be in line with Fair Information Practices we will take the following responsive
							action, should a data breach occur:
						</strong>
					</div>
					<div class="innerText">We will notify you via email</div>
					<div class="innerText">
						<strong>•</strong>
						Within 7 business days
					</div>
					<div class="innerText">
						We also agree to the Individual Redress Principle which requires that individuals have the right
						to legally pursue enforceable rights against data collectors and processors who fail to adhere
						to the law. This principle requires not only that individuals have enforceable rights against
						data users, but also that individuals have recourse to courts or government agencies to
						investigate and/or prosecute non-compliance by data processors.
					</div>
					<p>
						<strong>
							<span id="canSpam"></span>
							<br />
						</strong>
					</p>
					<div class="blueText">
						<strong>CAN SPAM Act</strong>
					</div>
					<p>
						<strong></strong>
					</p>
					<div class="innerText">
						The CAN-SPAM Act is a law that sets the rules for commercial email, establishes requirements for
						commercial messages, gives recipients the right to have emails stopped from being sent to them,
						and spells out tough penalties for violations.
						<p></p>
					</div>
					<div class="innerText">
						<strong>We collect your email address in order to:</strong>
					</div>
					<div class="innerText">
						<strong>•</strong>
						Send information, respond to inquiries, and/or other requests or questions
					</div>
					<div class="innerText">
						<strong>•</strong>
						Process orders and to send information and updates pertaining to orders.
					</div>
					<div class="innerText">
						<strong>•</strong>
						Send you additional information related to your product and/or service
					</div>
					<div class="innerText">
						<strong>To be in accordance with CANSPAM, we agree to the following:</strong>
					</div>
					<div class="innerText">
						<strong>•</strong>
						Not use false or misleading subjects or email addresses.
					</div>
					<div class="innerText">
						<strong>•</strong>
						Identify the message as an advertisement in some reasonable way.
					</div>
					<div class="innerText">
						<strong>•</strong>
						Include the physical address of our business or site headquarters.
					</div>
					<div class="innerText">
						<strong>•</strong>
						Monitor third-party email marketing services for compliance, if one is used.
					</div>
					<div class="innerText">
						<strong>•</strong>
						Honor opt-out/unsubscribe requests quickly.
					</div>
					<div class="innerText">
						<strong>•</strong>
						Allow users to unsubscribe by using the link at the bottom of each email.
					</div>
					<div class="innerText">
						<strong>
							<br />
							If at any time you would like to unsubscribe from receiving future emails, you can email us
							at
						</strong>
					</div>
					<div class="innerText">
						<strong>•</strong>
						Follow the instructions at the bottom of each email.
					</div>
					<p>
						<strong>and we will promptly remove you from ALL correspondence.</strong>
					</p>
				</div>
				<p>
					<strong>
						<br />
						<span id="ourCon"></span>
						<br />
					</strong>
				</p>
				<div class="blueText">
					<strong>Contacting Us</strong>
				</div>
				<p>
					<strong></strong>
				</p>
				<div class="innerText">
					If there are any questions regarding this privacy policy, you may contact us using the information
					below.
					<p></p>
				</div>
				<div class="innerText">bitofhelp.net</div>
				<div class="innerText">support@bitofhelp.net</div>
			</div>
		</div>
	);
}

import { dlg } from '../dlg';

export function NewPostDlg(p: { edit_url: string; pin: string }) {
	let el: HTMLDivElement;

	return (
		<div class="dlgbg" ref={el}>
			<div class="dlg" style={{ width: '500px' }}>
				<h4 class="dlg-header nomargin" style={{ color: 'lime' }}>
					Post was created successfully
				</h4>
				<div class="dlg-content">
					{p.pin ? (
						<p style={{ 'line-height': 2, 'text-align': 'center' }}>
							To edit your post:
							<br />
							sign in using your phone number and pin code:&nbsp;
							<span style={{ background: 'aqua', padding: '5px', 'border-radius': '5px' }}>{p.pin}</span>
						</p>
					) : (
						<p>To edit your post, sign in using your phone number and pin code</p>
					)}
					{/* <p style={{ 'margin-top': 0, color: 'darkviolet' }}>Bookmark this address for future access.</p> */}
					{/* <div class="row">
						<input readonly value={p.edit_url} style={{ 'font-size': '12px' }} />
					</div> */}
				</div>
				<div class="dlg-footer">
					<button onClick={(e) => dlg.close(el)}>OK</button>
				</div>
			</div>
		</div>
	);
}

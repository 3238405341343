import { css } from 'goober';
import { dlg } from '../dlg';
import { Icon } from '../components/icon';
import { config } from '../services/config';

const c_style = css`
	width: 600px;
	padding: 20px;
	align-items: center;
	background: #222;
	border-width: 0;
	color: white;
`;

function get_class_list(names: string[]) {
	let r = {};
	names.forEach((n) => (r[n] = true));
	return r;
}

function Roadmap() {
	let el: HTMLDivElement;
	return (
		<div class="dlgbg" ref={el}>
			<div classList={get_class_list(['dlg', c_style])}>
				<h1>Development Roadmap</h1>
				<Icon name={'map'} style={{ 'font-size': '32px' }} />
				<ul>
					<li>Provider Badges</li>
					<li>Flexable data privacy policy</li>
					<li>System support and contact notifications</li>
					<li>Email and Text verification</li>
					<li>Customizable organization landing pages</li>
				</ul>
				<span style={{ 'font-size': '14px', color: '#999' }}>version: {config.version}</span>
				<br />
				<button onClick={(e) => dlg.close(el)}>Close</button>
			</div>
		</div>
	);
}

export function openRoadmap() {
	return dlg.open(Roadmap, {});
}

import { AdminPage } from './pages/admin';
import { HomePage } from './pages/home';
import { OrgPage } from './pages/orgPage';
import { PrivacyPolicy } from './pages/privacy_policy';
import { Terms } from './pages/terms';

export const routes = [
	{
		path: '/',
		component: HomePage
	},
	{
		path: '/privacy_policy',
		component: PrivacyPolicy
	},
	{
		path: '/terms',
		component: Terms
	},
	{
		path: '/admin',
		component: AdminPage
	},
	{
		path: '/org/*',
		component: OrgPage
	}
];

import { makeAutoObservable } from 'mobx';
import { dlg } from '../dlg';
import { api } from '../services/api';
import { state } from '../services/state';

import { For } from 'solid-js';
import { openViewPostDlg } from './viewPostDlg';
import { date_str_iso, formateDateStr, removeFromArray } from '../utils/utils';
import { openActionDlg } from './actionDlg';
import { coming_soon } from '../components/coming_soon';
import { openClientDlg } from './clientDlg';
import { openLoader } from '../components/loading';

interface IHistory {
	action: IAction;
	post: IPost;
	client: IUser;
	user: IUser;
}

function HistoryDlg(p: {}) {
	let el: HTMLDivElement;

	let l = makeAutoObservable({
		for_name: state.user_org?.name || state.user.name,
		history: [] as IHistory[],
		total: 0,
		from_date: null as string,
		to_date: null as string
	});

	async function search() {
		let f: IActionFilter = { user_id: state.user.id };
		if (state.is_org_user) {
			f = { org_id: state.user_org.id };
		}
		if (l.from_date) f.from_date = l.from_date;
		if (l.to_date) f.to_date = l.to_date;
		let actions = await openLoader(api.get_actions(f));

		let post_ids = actions.filter((a) => a.post_id).map((r) => r.post_id);
		let postings = post_ids.length ? await api.get_posts({ ids: post_ids }) : [];

		let user_ids = actions.map((r) => r.client_id).concat(actions.map((a) => a.user_id));
		let users = user_ids.length ? await api.get_users({ ids: user_ids }) : [];

		l.history = [];
		for (let a of actions) {
			let post = postings.find((p) => p.id === a.post_id);
			let client = users.find((c) => c.id === a.client_id);
			let user = users.find((u) => u.id === a.user_id);
			l.history.push({ action: a, client, post, user });
		}

		sum_total();
	}

	function sum_total() {
		l.total = 0;
		for (let h of l.history) {
			let a = h.action;
			if (a.amount) l.total += a.amount;
		}
	}

	async function edit_action(h: IHistory) {
		await openActionDlg(h.action);
		if (!h.action.id) {
			removeFromArray(h, l.history);
		}
		sum_total();
	}

	function this_week() {
		var curr = new Date(); // get current date
		var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
		var last = first + 6; // last day is the first day + 6

		l.from_date = new Date(curr.setDate(first)).toDateString();
		l.to_date = new Date(curr.setDate(last)).toDateString();
		search();
	}

	function this_month() {
		let year = new Date().getFullYear();
		let month = new Date().getMonth();
		l.from_date = new Date(year, month, 1).toDateString();
		l.to_date = new Date(year, month + 1, 0).toDateString();
		search();
	}

	function this_year() {
		let year = new Date().getFullYear();
		l.from_date = new Date(year, 0, 1).toDateString();
		l.to_date = new Date(year, 12, 0).toDateString();
		search();
	}

	this_month();

	return (
		<div class="dlgbg" ref={el}>
			<div class="dlg">
				<h4 class="dlg-header nomargin">Action History ({l.for_name})</h4>
				<div class="dlg-content">
					<div class="flex gap20 center" style={{ 'margin-bottom': '10px' }}>
						<div>
							<label>Date Range&nbsp;</label>
							<input
								type="date"
								value={date_str_iso(l.from_date)}
								onChange={(e) => {
									l.from_date = e.target.value;
									search();
								}}
							/>
							<label>&nbsp;-&nbsp;</label>
							<input
								type="date"
								value={date_str_iso(l.to_date)}
								onChange={(e) => {
									l.to_date = e.target.value;
									search();
								}}
							/>
						</div>

						<a style={{ color: 'green' }} onClick={this_week}>
							This Week
						</a>
						<a style={{ color: 'green' }} onClick={this_month}>
							This Month
						</a>
						<a style={{ color: 'green' }} onClick={this_year}>
							This Year
						</a>
					</div>
					<table class="data_table">
						<thead>
							<tr>
								<th>Date</th>
								<th>Type</th>
								<th>Amount</th>
								<th>Resource</th>
								<th>Post</th>
								<th>Client</th>
								<th>User</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							<For
								each={l.history}
								fallback={
									<tr>
										<td colSpan={8}>No Records Found</td>
									</tr>
								}
							>
								{(h) => (
									<tr>
										<td>{formateDateStr(h.action.action_date)}</td>
										<td>{h.action.type}</td>
										<td>{h.action.amount}</td>
										<td>{h.action.resource}</td>
										<td>
											<a onClick={(e) => openViewPostDlg(h.post)}>{h.post?.title}</a>
										</td>
										<td>
											<a onClick={(e) => openClientDlg(h.client.id)}>{h.client?.name}</a>
										</td>
										<td>{h.user.name}</td>
										<td>
											<a onClick={(e) => edit_action(h)}>Edit</a>
										</td>
									</tr>
								)}
							</For>
						</tbody>
					</table>
				</div>
				<div class="dlg-footer">
					<span class="grow">Total: ${l.total.toFixed(2)}</span>
					<button onClick={coming_soon}>Print</button>
					<button onClick={coming_soon}>Download (csv)</button>
					<button onClick={(e) => dlg.close(el)}>Close</button>
				</div>
			</div>
		</div>
	);
}

export function openHistory() {
	dlg.open(HistoryDlg, {});
}

import { makeAutoObservable } from 'mobx';
import { dlg } from '../dlg';
import { api } from '../services/api';
import { state } from '../services/state';
import { autofocus } from '../utils/autofocus';
import { openToast } from './toast';
import { onMount } from 'solid-js';

function SupportDlg(p: { msg: INotification }) {
	let el: HTMLDivElement;
	let el_name: HTMLInputElement;
	let el_message: HTMLTextAreaElement;
	const is_new = !p.msg.id;
	const l = makeAutoObservable({ ...p.msg });

	async function save() {
		if (!l.message) {
			openToast('Info is required', true);
			return;
		}

		Object.assign(p.msg, l);
		await api.save_notification(p.msg);
		openToast('Message Sent');
		dlg.close(el, p.msg);
	}

	async function deleteNotification() {
		if (!confirm('Are you sure you want to delete this notification?')) return;
		await api.delete_notification(p.msg);
		dlg.close(el);
	}

	onMount(() => {
		if (p.msg.user_id) el_message.focus();
		else el_name.focus();
	});

	return (
		<div class="dlgbg" ref={el}>
			<div class="dlg" style={{ width: '500px' }}>
				<h4 class="dlg-header nomargin">Notification</h4>
				<div class="dlg-content">
					<div class="row">
						<label>Your Name</label>
						<input value={p.msg.name} onChange={(e) => (p.msg.name = e.target.value)} ref={el_name} />
					</div>
					<div class="row">
						<label>Your Email</label>
						<input value={p.msg.email} onChange={(e) => (p.msg.email = e.target.value)} />
					</div>
					<div class="row">
						<label>Your Phone Number</label>
						<input value={p.msg.phone} onChange={(e) => (p.msg.phone = e.target.value)} />
					</div>
					<div class="row">
						<label>To</label>
						{<input value={p.msg.to_name} readOnly={true} />}
					</div>
					<div class="row">
						<label>Message</label>
						<textarea
							ref={el_message}
							style={{ height: '100px', resize: 'none' }}
							value={l.message}
							onInput={(e) => (l.message = e.target.value)}
						/>
					</div>
				</div>
				<div class="dlg-footer">
					{!is_new && (
						<button class="danger" onclick={deleteNotification}>
							Delete
						</button>
					)}
					<span class="grow"></span>
					<button class="primary" onClick={save}>
						Send
					</button>
					<button onClick={(e) => dlg.close(el)}>Cancel</button>
				</div>
			</div>
		</div>
	);
}

export function openSupportDlg(n: INotification): Promise<void> {
	if (!n) {
		n = {
			user_id: state.user?.id,
			to_user_id: null,
			to_name: 'support team',
			message: null,
			name: state.user?.name || null,
			email: state.user?.email || null,
			phone: state.user?.phone || null
		};
	}
	return dlg.open(SupportDlg, { msg: n });
}

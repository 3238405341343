import { makeAutoObservable } from 'mobx';

const supabase_url = 'https://xxsxatakacoteocolrzz.supabase.co/functions/v1/hello-world';
const local_url = 'http://localhost:8000/hello-world';
const deno_url = 'https://westbrma-bitofhelp.deno.dev/hello-world';

class Config {
	local = document.location.host.includes('localhost');
	base_url: string = deno_url;
	session_id: string = null;
	version: string = null;

	constructor() {
		makeAutoObservable(this);
	}

	async init() {
		//@ts-ignore
		this.version = window.config.version;

		if (this.local) {
			return fetch(local_url)
				.then((r) => (this.base_url = local_url))
				.catch((e) => {});
		}
	}
}

export const config = new Config();

import { makeAutoObservable } from 'mobx';
import { api } from '../services/api';
import { dlg } from '../dlg';
import { For } from 'solid-js';
import { openViewPostDlg } from './viewPostDlg';
import { openPostingDlg } from './postingDlg';
import { formateDateStr, removeFromArray } from '../utils/utils';
import { state } from '../services/state';

function UserPostingsDlg() {
	let el: HTMLDivElement;

	const l = makeAutoObservable({
		list: [] as IPost[]
	});

	api.get_posts({ user_id: state.user.id }).then((r) => {
		for (let i = 0; i < r.length; i++) {
			let p = r[i];
			let found = state.posts.find((pp) => pp.id === p.id);
			if (found) l.list.push(found);
			else l.list.push(p);
		}
	});

	return (
		<div class="dlgbg" ref={el}>
			<div class="dlg" style={{ width: '500px' }}>
				<h4 class="dlg-header nomargin">Posts by {state.user.name}</h4>
				<div class="dlg-content" style={{ padding: 0 }}>
					<table>
						<For
							each={l.list}
							fallback={
								<tr>
									<td>No Posts Found</td>
								</tr>
							}
						>
							{(p) => (
								<tr>
									<td>{formateDateStr(p.post_date)}</td>
									<td>{p.title}</td>
									<td>
										<a onClick={(e) => openViewPostDlg(p)}>View</a>
									</td>

									<td>
										<a
											onClick={(e) => {
												openPostingDlg(p).then((r) => {
													if (!p.id) {
														removeFromArray(p, l.list);
													}
												});
											}}
										>
											Edit
										</a>
									</td>
								</tr>
							)}
							{/* {(e) => <Posting p={e} edit={p.edit} />} */}
						</For>
					</table>
				</div>
				<div class="dlg-footer">
					<button onClick={(e) => dlg.close(el)}>Close</button>
				</div>
			</div>
		</div>
	);
}

export function openUserPostings() {
	return dlg.open(UserPostingsDlg, {});
}

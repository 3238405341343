import { dlg } from '../dlg';
import { PostTag } from '../components/posting';
import { PostType } from './postingDlg';
import { Badges } from './badgeDlg';
import { get_loc_desc } from '../utils/utils';

function OrgInfoDlg(p: { o: IOrg }) {
	let el: HTMLDivElement;
	const o = p.o;

	return (
		<div class="dlgbg" ref={el}>
			<div class="dlg" style={{ width: '500px' }}>
				<div class="dlg-header center">
					<PostTag t={PostType.org_info} />
					<div class="grow" style={{}}>
						{o.name}
					</div>
				</div>
				<div class="dlg-content">
					<div class="row">
						<label>Description</label>
						<div class="valuebox">{o.description}</div>
					</div>
					<div class="row">
						<label>Phone</label>
						<div class="valuebox">{o.phone}</div>
					</div>
					<div class="row">
						<label>Address</label>
						<div class="valuebox">{o.address}</div>
					</div>
					<div class="row">
						<label>Location</label>
						<div class="valuebox">{get_loc_desc(o.location)}</div>
					</div>
					<div class="row">
						<label>Tags</label>
						<div class="valuebox">{o.tags.join(', ')}</div>
					</div>
				</div>
				<div class="dlg-footer">
					<Badges org_id={o.id} badges={o.badges} />
					<span class="grow" />
					<button class="primary" onClick={(e) => dlg.close(el)}>
						Close
					</button>
				</div>
			</div>
		</div>
	);
}

export function openOrgInfo(o: IOrg): Promise<IPost> {
	return dlg.open(OrgInfoDlg, { o });
}

import { count } from 'firebase/firestore';
import { makeAutoObservable } from 'mobx';
import { JSXElement } from 'solid-js';
import { render } from 'solid-js/web';

interface IDlg extends HTMLElement {
	resolve?: (data: any) => void;
	value?: IDlg;
}

class Dlg {
	state = makeAutoObservable({ count: 0 });

	open<T>(comp: (p: T) => JSXElement, props: T): Promise<any> {
		this.state.count++;
		return new Promise((resolve, reject) => {
			let temp: any = document.createElement('div');
			document.body.appendChild(temp);
			render(() => comp(props), temp);
			temp.resolve = resolve;
		});
	}

	async close(el: IDlg, data?: any, fadeout = true) {
		if (el.value) el = el.value;
		if (!el.resolve) el = el.parentElement;

		el.resolve(data);
		if (fadeout) {
			el.classList.add('fadeout');
			await new Promise<void>((resolve) => {
				el.addEventListener('animationend', () => resolve());
			});
		}

		this.state.count--;
		el.remove();
	}
}

export const dlg = new Dlg();

import { makeAutoObservable } from 'mobx';
import { dlg } from '../dlg';
import { api } from '../services/api';
import { autofocus } from '../utils/autofocus';
import { debounce } from '../utils/utils';

function OrgSelectDlg(p: { message: string }) {
	let el: HTMLDivElement = null;
	const l = makeAutoObservable({ term: '' as string, list: [] as IOrg[] });

	async function _on_search() {
		if (l.term.length < 3) return;
		l.list = await api.get_orgs({ like_name: l.term });
	}

	const on_search = debounce(_on_search);

	return (
		<div class="dlgbg" ref={el}>
			<div class="dlg">
				<div class="dlg-header">
					<span>{p.message || 'Find Organization'}</span>
				</div>
				<div class="dlg-content">
					<input
						ref={(e) => autofocus(e)}
						value={l.term}
						onInput={(e) => {
							l.term = e.target.value;
							on_search();
						}}
					/>
					<br />
					{l.list.map((o) => (
						<a
							onClick={(e) => {
								dlg.close(el, o);
							}}
						>
							{o.name}
						</a>
					))}
				</div>
				<div class="dlg-footer">
					<button onClick={(e) => dlg.close(el)}>Close</button>
				</div>
			</div>
		</div>
	);
}

export function openOrgSelectBox(message?: string): Promise<IOrg> {
	return dlg.open(OrgSelectDlg, { message });
}

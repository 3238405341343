import { css } from 'goober';
import { dlg } from '../dlg';

const c_style = css`
	width: 600px;
	padding: 20px;
	align-items: center;
	background: #222;
	border-width: 0;
	color: white;
`;

function get_class_list(names: string[]) {
	let r = {};
	names.forEach((n) => (r[n] = true));
	return r;
}

function AboutDlg() {
	let el: HTMLDivElement;
	return (
		<div class="dlgbg" ref={el} onClick={(e) => dlg.close(el)}>
			<div classList={get_class_list(['dlg', c_style])}>
				<h1>Welcome to Bit of Help</h1>
				<img src="/icons8-help-50.png" />
				<p>A free platform to help connecting those in need with their community</p>
				<ul>
					<li>Discover local organizations and resources</li>
					<li>Create help wanted/offered posts</li>
					<li>Share provider information</li>
					<li>Post organization events</li>
				</ul>
				<button onClick={(e) => dlg.close(el)}>Close</button>
			</div>
		</div>
	);
}

export function openAboutDlg() {
	return dlg.open(AboutDlg, {});
}

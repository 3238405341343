import { css } from 'goober';
import { openAboutDlg } from '../dialogs/aboutDlg';
import { openRoadmap } from '../dialogs/roadmap';
import { openSupportDlg } from '../dialogs/supportDlg';

const c_footer = css`
	background: var(--footer-bg-color);
	display: flex;
	justify-content: center;
	gap: 20px;
	padding: 4px;
	border-top: 1px solid #999;
`;

export function Footer() {
	return (
		<div class={c_footer}>
			<a onClick={openAboutDlg}>About</a>
			<a href="/terms">Terms</a>
			<a href="/privacy_policy">Privacy</a>
			<a class="large" onClick={openRoadmap}>
				Roadmap
			</a>
			<a
				onClick={(e) => {
					openSupportDlg(null);
				}}
			>
				Support
			</a>
		</div>
	);
}

import { createEffect, enableExternalSource, onMount } from 'solid-js';
import { render } from 'solid-js/web';
import { Loading } from './components/loading';
import { configure, Reaction, untracked } from 'mobx';
import { state } from './services/state';
import { ToastList } from './dialogs/toast';
import { ApiError } from './utils/apiError';
import { HashRouter, Router, useLocation } from '@solidjs/router';
import { routes } from './routes';
import { Footer } from './components/footer';
import { Navbar, UserBar } from './components/navbar';
import { Windows } from './components/windowMgr';
import { dlg } from './dlg';

configure({
	enforceActions: 'never'
});

let id = 0;
enableExternalSource((fn, trigger) => {
	const reaction = new Reaction(`externalSource@${++id}`, trigger);
	return {
		track: (x) => {
			let next;
			reaction.track(() => (next = fn(x)));
			return next;
		},
		dispose: () => reaction.dispose()
	};
}, untracked);

function RootComp(props) {
	let location = useLocation();
	createEffect(() => {
		state.page = location.pathname;
	});

	return (
		<>
			<Navbar />
			{state.has_user && state.show_user_bar && <UserBar />}
			{props.children}
			<Footer />
		</>
	);
}

function App() {
	onMount(() => {
		let mq = window.matchMedia('(max-width: 600px)');
		function onChange() {
			state.mobile = mq.matches;
		}
		mq.onchange = onChange;
		onChange();
	});
	return (
		<div id="app_main" inert={dlg.state.count > 0}>
			<Router root={RootComp}>{routes}</Router>
			<Windows />
			<Loading />
			<ToastList />
		</div>
	);
}

render(() => <App />, document.getElementById('app'));

addEventListener('unhandledrejection', (event) => {
	if (event.reason instanceof ApiError) return;
	alert('UNHANDLED ERROR: ' + event.reason);
});

addEventListener('error', (event) => {
	alert(`UNHANDLED ERROR: ${event.message}`);
});

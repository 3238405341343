import { css } from 'goober';
import { makeAutoObservable } from 'mobx';
import { For } from 'solid-js';
import { removeFromArray } from '../utils/utils';

const c_toast = css`
	background: royalblue;
	font-size: 16px;
	padding: 12px;
	border-radius: 5px;
	color: white;
	animation: fadeIn 0.3s;

	&.error {
		background: darkred;
	}
`;

const c_toast_list = css`
	position: fixed;
	bottom: 20px;
	right: 20px;
	display: flex;
	flex-direction: column;
	gap: 6px;
	z-index: 1000;
`;

let toast_id = 0;

interface IToast {
	message: string;
	error: boolean;
	id: number;
}

const lstate = makeAutoObservable({
	toasts: [] as IToast[]
});

function Toast(p: { t: IToast }) {
	setTimeout(() => {
		removeFromArray(p.t, lstate.toasts);
	}, 8000);

	const classes = {};
	classes[c_toast] = true;
	classes['error'] = p.t.error;

	return <div classList={classes}>{p.t.message}</div>;
}

export function ToastList() {
	return (
		<div class={c_toast_list}>
			<For each={lstate.toasts}>{(t) => <Toast t={t} />}</For>
		</div>
	);
}

export function openToast(message: string, error: boolean = false) {
	lstate.toasts.push({ message, error, id: toast_id++ });
}

enum ShareLevel {
	private = 0,
	org_providers = 1,
	all = 2
}

interface IShareDetails {
	id: number;
	name: string;
}

export const sharing: IShareDetails[] = [
	{ id: ShareLevel.private, name: 'only specific organizations' },
	{ id: ShareLevel.org_providers, name: 'all organization providers' },
	{ id: ShareLevel.all, name: 'all providers' }
];

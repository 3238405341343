import { makeAutoObservable } from 'mobx';
import { dlg } from '../dlg';
import { api } from '../services/api';
import { autofocus } from '../utils/autofocus';
import { openToast } from './toast';

function ClientInfoDlg(p: { info: IClientData }) {
	let el: HTMLDivElement;
	const is_new = !p.info.id;
	const l = makeAutoObservable({
		...p.info
	});

	async function save() {
		if (!l.name || !l.data) {
			openToast('Name and Value are required', true);
			return;
		}
		await api.save_client_data(l);
		Object.assign(p.info, l);
		dlg.close(el);
	}

	async function delete_object() {
		if (!confirm('Are you sure you want to delete this client info?')) return;
		await api.delete_client_data(p.info);
		p.info.id = null;
		dlg.close(el);
	}

	return (
		<div class="dlgbg" ref={el}>
			<div class="dlg" style={{ width: '400px' }}>
				<h4 class="dlg-header nomargin">Client Info</h4>
				<div class="dlg-content">
					<div class="row">
						<label>Name</label>
						<input value={l.name} onChange={(e) => (l.name = e.target.value)} ref={(e) => autofocus(e)} />
					</div>
					<div class="row">
						<label>Data</label>
						<textarea
							value={l.data}
							onChange={(e) => (l.data = e.target.value)}
							style={{ height: '100px', resize: 'none' }}
						/>
					</div>
				</div>
				<div class="dlg-footer">
					{!is_new && (
						<button class="danger" onclick={delete_object}>
							Delete
						</button>
					)}
					<span class="grow"></span>
					<button class="primary" onClick={save}>
						Save
					</button>
					<button onClick={(e) => dlg.close(el)}>Cancel</button>
				</div>
			</div>
		</div>
	);
}

export function openClientInfoDlg(info: IClientData): Promise<IClientData> {
	return dlg.open(ClientInfoDlg, { info });
}

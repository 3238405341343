import { action, makeAutoObservable } from 'mobx';
import { dlg } from '../dlg';
import { api, PendingActionType } from '../services/api';
import { state } from '../services/state';
import { autofocus } from '../utils/autofocus';
import { openToast } from './toast';
import { openResourceDlg } from './resourceDlg';
import { is_expired } from '../utils/utils';

function ActionDlg(p: { action: IAction }) {
	let el: HTMLDivElement;
	const is_new = !p.action.id;
	const l = makeAutoObservable({
		user: null as IUser,
		org: null as IOrg,
		post: null as IPost,
		action: { ...p.action }
	});
	const a = l.action;
	const read_only = a.org_id ? a.org_id != state.user_org?.id : a.user_id !== state.user.id;

	if (a.post_id) {
		l.post = state.posts.find((p) => p.id === a.post_id);
		if (!l.post) {
			api.get_post(a.post_id).then((r) => (l.post = r));
		}
	}

	if (a.user_id) {
		api.get_user({ id: a.user_id }).then((r) => (l.user = r));
	}

	if (a.org_id) {
		api.get_org({ id: a.org_id }).then((r) => (l.org = r));
	}

	async function save() {
		a.pending = a.type === PendingActionType;
		/*if (!a.pending && !a.info) {
			openToast('Info is required', true);
			return;
		}*/

		await api.save_action(a);
		Object.assign(p.action, a);
		refresh_post();

		dlg.close(el, p.action);
	}

	function refresh_post() {
		if (l.post) {
			api.get_post(l.post.id).then((r) => Object.assign(l.post, r));
		}
	}

	async function deleteAction() {
		if (!confirm('Are you sure you want to delete this action?')) return;
		await api.delete_action(p.action);
		a.id = p.action.id = null;
		a.pending = false;
		refresh_post();
		dlg.close(el);
	}

	return (
		<div class="dlgbg" ref={el}>
			<div class="dlg" style={{ width: '400px' }}>
				<h4 class="dlg-header nomargin">
					Action
					{l.user && <span>&nbsp;by {l.user.name}</span>}
					{l.org && <span>&nbsp;({l.org.name})</span>}
				</h4>
				<div class="dlg-content">
					<div class="row">
						<label>Action Type</label>
						<select
							disabled={read_only}
							value={a.type}
							onChange={(e) => (a.type = e.target.value)}
							ref={(e) => autofocus(e)}
						>
							<option>{PendingActionType}</option>
							<option>Payment</option>
							<option>Advice or Information</option>
							<option>Food/Supplies</option>
							<option>Donation</option>
							<option>Transportation</option>
							<option>Other</option>
						</select>
					</div>
					<div class="row">
						<label>Resource (optional)</label>
						<div class="flex gap10">
							<input
								disabled={read_only}
								class="grow"
								value={a.resource}
								onInput={(e) => (a.resource = e.target.value)}
							/>
							{state.is_org_user && !read_only && (
								<button
									onClick={(e) => {
										openResourceDlg().then((r) => {
											if (r) a.resource = r;
										});
									}}
								>
									Lookup
								</button>
							)}
						</div>
					</div>
					<div class="row">
						<label>Amount (optional)</label>
						<input
							disabled={read_only}
							type="number"
							step={1}
							value={a.amount}
							onInput={(e) => (a.amount = parseFloat(e.target.value))}
						/>
					</div>
					<div class="row">
						<label>Notes</label>
						<textarea
							disabled={read_only}
							style={{ height: '150px', resize: 'none' }}
							value={a.info}
							onInput={(e) => (a.info = e.target.value)}
						/>
					</div>
					{l.post && (
						<div class="row">
							<label>
								<input
									checked={a.complete}
									type="checkbox"
									onChange={(e) => (a.complete = !a.complete)}
								/>
								Mark Post as Complete
							</label>
						</div>
					)}
				</div>
				<div class="dlg-footer">
					{!is_new && !read_only && (
						<button class="danger" onclick={deleteAction}>
							Delete
						</button>
					)}
					<span class="grow"></span>
					{!read_only && (
						<button class="primary" onClick={save}>
							Save
						</button>
					)}
					<button onClick={(e) => dlg.close(el)}>{read_only ? 'Close' : 'Cancel'}</button>
				</div>
			</div>
		</div>
	);
}

export function openActionDlg(action: IAction): Promise<IAction> {
	return dlg.open(ActionDlg, { action });
}

import { dlg } from '../dlg';
import { api } from '../services/api';
import { makeAutoObservable } from 'mobx';
import { state } from '../services/state';
import { autofocus } from '../utils/autofocus';

const org_resource_cache = new Map<number, string[]>();

function ResourceDlg() {
	let el: HTMLDivElement;

	const l = makeAutoObservable({ list: [] as string[], filter: null as string });

	async function load() {
		let c = org_resource_cache.get(state.user_org.id);
		if (c) l.list = c;
		else {
			let r = await api.resources(state.user_org.id);
			l.list = r;
			org_resource_cache.set(state.user_org.id, r);
		}
	}

	load();

	function filter_list() {
		if (!l.filter) return l.list;
		return l.list.filter((r) => r.toLowerCase().includes(l.filter));
	}

	return (
		<div class="dlgbg" ref={el}>
			<div class="dlg">
				<div class="dlg-header">Org Resources</div>
				<div class="dlg-content">
					<div class="row">
						<label>Search</label>
						<input
							value={l.filter}
							oninput={(e) => (l.filter = e.target.value.toLowerCase())}
							ref={(e) => autofocus(e)}
						/>
					</div>
					<div class="flex column gap10">
						{filter_list().map((r) => (
							<a
								onClick={(e) => {
									dlg.close(el, r, false);
								}}
							>
								{r}
							</a>
						))}
					</div>
				</div>
				<div class="dlg-footer">
					<button onClick={(e) => dlg.close(el)}>Close</button>
				</div>
			</div>
		</div>
	);
}

export function openResourceDlg(): Promise<string> {
	return dlg.open(ResourceDlg, {});
}

import { css } from 'goober';
import { makeAutoObservable } from 'mobx';
import { state } from '../services/state';
import { removeFromArray } from '../utils/utils';

const c_tags = css`
	display: flex;
	gap: 10px;
	position: relative;
	border: 1px solid royalblue;
	border-radius: 5px;
	padding: 5px;
	width: 100%;
	flex-wrap: wrap;
`;

const c_tag = css`
	background: aquamarine;
	padding: 2px 4px;
	border-radius: 5px;
	cursor: pointer;
	&:hover {
		background: red;
	}
`;

const c_list = css`
	position: fixed;
	background: white;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	gap: 2px;
	padding: 4px;
	max-height: 150px;
	overflow: auto;
`;

const c_list_item = css`
	cursor: pointer;
	color: blue;
	&:hover {
		background: aqua;
	}
`;

export function TagBox(p: { tags: string[] }) {
	let elref: HTMLElement = null;
	let el_list: HTMLElement = null;

	const l = makeAutoObservable({ show_list: false, list_x: 0, list_y: 0 });
	function remove_tag(t: string) {
		removeFromArray(t, p.tags);
	}

	function add_tag(t) {
		if (p.tags.includes(t)) return;
		p.tags.push(t);
		l.show_list = false;
	}

	function open_list(e: MouseEvent) {
		if (e.target === el_list) return;
		let bb = elref.getBoundingClientRect();
		l.list_x = bb.left;
		l.list_y = bb.top + bb.height;
		let max = window.innerHeight - 150;
		if (l.list_y > max) l.list_y = max;
		l.show_list = true;
	}

	return (
		<div class={c_tags} onClick={open_list} ref={elref as any}>
			{p.tags.map((t) => (
				<div
					class={c_tag}
					onClick={(e) => {
						e.preventDefault();
						e.stopPropagation();
						remove_tag(t);
					}}
				>
					{t}
				</div>
			))}
			{!p.tags.length && <div style={{ padding: '2px 4px' }}>&nbsp;</div>}
			<div
				ref={el_list as any}
				class="dlgbg"
				style={{ display: l.show_list ? '' : 'none' }}
				onClick={(e) => {
					l.show_list = false;
				}}
			>
				<div class={c_list} style={{ top: l.list_y + 'px', left: l.list_x + 'px' }}>
					{state.tags.map((t) => (
						<div
							class={c_list_item}
							onClick={(e) => {
								e.stopPropagation();
								e.preventDefault();
								add_tag(t);
							}}
						>
							{t}
						</div>
					))}
				</div>
			</div>
		</div>
	);
}

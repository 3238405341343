export function removeFromArray<T>(item: T, arr: T[]) {
	let idx = arr.indexOf(item);
	if (idx >= 0) arr.splice(idx, 1);
}

export function getRandomInt(min: number, max: number): number {
	return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function getRandomItem<T>(arg: T[]): T {
	if (arg.length == 0) return null;
	return arg[getRandomInt(0, arg.length - 1)];
}

export function getRandomChance(chance: number): boolean {
	if (chance <= 0 || chance >= 1) {
		throw new Error('Invalid RandomChance parameter value');
	}

	return Math.random() <= chance;
}

export function parseISOLocal(input: HTMLDataElement): Date {
	let s = input.value;
	var b = s.split(/\D/);
	return new Date(`${b[1]}-${b[2]}-${b[0]}`);
	//return new Date(b[0], b[1] - 1, b[2], b[3], b[4], b[5]);
}

export function dateInputValue(d: Date): string {
	//if (d) return d.toISOString().substring(0, 10);
	let m: any = d.getMonth() + 1;
	if (m < 10) m = '0' + m;

	let date: any = d.getDate();
	if (date < 10) date = '0' + date;
	if (d) return `${d.getFullYear()}-${m}-${date}`;
}

export function formatDate(d: Date) {
	if (!d) return '';
	return d.toLocaleDateString();
}

export function formateDateStr(d: string) {
	if (!d) return '';
	return new Date(d).toLocaleDateString();
}

export function date_str_iso(str: string): string {
	if (!str) return '';
	let r = new Date(str).toISOString();
	return r.substring(0, 10);
}

export function parse_iso_date(input: HTMLInputElement): string {
	let s = input.value;
	var b = s.split(/\D/);
	return new Date(`${b[1]}-${b[2]}-${b[0]}`).toString();
	//return new Date(b[0], b[1] - 1, b[2], b[3], b[4], b[5]);
}

export function shuffleArray(a: any[]) {
	for (let i = a.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[a[i], a[j]] = [a[j], a[i]];
	}
}

export function format_phone(phone: string): string {
	if (!phone) return null;
	let raw = raw_phone_number(phone)?.toString();
	if (raw.length !== 10) return phone;

	// if (!phoneNumberString) return '';
	// var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
	// var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
	// if (match) {
	// 	return '(' + match[1] + ') ' + match[2] + '-' + match[3];
	// }
	return `(${raw.substring(0, 3)}) ${raw.substring(3, 6)}-${raw.substring(6, 10)}`;
}

export function raw_phone_number(phone: string): number {
	if (!phone) return null;
	let raw = ('' + phone).replace(/\D/g, '');
	return parseInt(raw);
}

export function validate_phone(phone: string): boolean {
	let raw = raw_phone_number(phone);
	return raw?.toString().length === 10;
}

export function add_days(date: Date, days: number): Date {
	var date = new Date(date.valueOf());
	date.setDate(date.getDate() + days);
	return date;
}

export function date_from_now(days: number): Date {
	return add_days(new Date(), days);
}

export function is_expired(date_str: string): boolean {
	if (!date_str) return false;
	let d = new Date(date_str);
	let now = new Date();
	return now.getTime() >= d.getTime();
}

export function debounce(func: Function, timeout = 500) {
	let timer;
	return (...args) => {
		clearTimeout(timer);
		timer = setTimeout(() => {
			func(args);
		}, timeout);
	};
}

export function get_loc_desc(loc: ILocation): string {
	return `${loc.name} (${loc.zip_code})`;
}

export function create_random_pin(): string {
	let r = '';
	for (let i = 0; i < 4; i++) {
		r += getRandomInt(0, 9);
	}
	return r;
}

import { For } from 'solid-js';
import { openActionDlg } from '../dialogs/actionDlg';
import { removeFromArray, formateDateStr } from '../utils/utils';

export function ClientActions(p: { actions: IAction[] }) {
	async function view_action(a: IAction) {
		await openActionDlg(a);
		if (!a.id) {
			removeFromArray(a, p.actions);
		}
	}

	return (
		<div class="row">
			<label>Actions</label>
			<table class="data_table">
				<thead>
					<tr>
						<th>date</th>
						<th>type</th>
						<th>amount</th>
						<th>owner</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					<For
						each={p.actions}
						fallback={
							<tr>
								<td>No Records Found</td>
							</tr>
						}
					>
						{(a) => (
							<tr>
								<td>{formateDateStr(a.action_date)}</td>
								<td>{a.type}</td>
								<td>{a.amount?.toFixed(2)}</td>
								<td>{a.org?.name || a.user?.name}</td>
								<td>
									<a onClick={(e) => view_action(a)}>view</a>
								</td>
							</tr>
						)}
					</For>
				</tbody>
			</table>
		</div>
	);
}

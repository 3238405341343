export function BackHomeBar() {
	return (
		<div
			style={{
				position: 'sticky',
				top: 0,
				background: 'var(--org-bar-color)',
				padding: '10px 0',
				'border-bottom': '1px solid var(--org-bar-line-color)'
			}}
		>
			<div class="inner">
				<a href="/">Back to Posts</a>
			</div>
		</div>
	);
}
